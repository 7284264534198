/* See our ng2-tel-input.directive.ts for where we use intl-tel-input. */

.iti {
    width: 100%;
}

.iti__dropdown-content {
    border: none !important;
    background-color: unset !important;
    pointer-events: all;
}
